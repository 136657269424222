import React, { useEffect, useMemo, useState } from "react";
import Seo from "../../components/seo";
import "../../styles/main.scss";
import { Link } from "gatsby";
// import Logo from "../components/Logo";
import { StaticImage } from "gatsby-plugin-image";
// import ArrowIcon from "../components/ArrowIcon";
import CadServiceGrid from "../../components/Cad-ServiceGrid";
import CadImageGrid from "../../components/Cad-ImageGrid";
import CadGifWithContentSlider from "../../components/Cad-GifWithContentSlider";
import CadTestimonials from "../../components/Cad-Testimonials";
import CadFAQs from "../../components/CadFAQs";
import CadServiceForm from "../../components/Cad-serviceForm";
import { CSSTransition } from "react-transition-group";
import EngineeringLeadForm from "../../components/EngineeringLeadForm";
import SedinCadLogo from "../../images/cad-service/sedin-cad-logo-black.svg";
import IndustryOne from "../../images/cad-service/au-steel-agriculture.svg";
import IndustryTwo from "../../images/cad-service/au-steel-food-b.svg";
import IndustryThree from "../../images/cad-service/au-steel-heavy-machinery.svg";
import IndustryFour from "../../images/cad-service/au-steel-industrial-auto.svg";
import IndustryFive from "../../images/cad-service/au-steel-structure.svg";
import IndustrySix from "../../images/cad-service/au-steel-automotive.svg";
import IndustrySeven from "../../images/cad-service/au-steel-oil-gas.svg";
import IndustryEight from "../../images/cad-service/au-steel-water.svg";
import IndustryNine from "../../images/cad-service/au-steel-packaging.svg";
import IndustryTen from "../../images/cad-service/au-steel-machinary.svg";
import IndustryEleven from "../../images/cad-service/au-steel-maritime.svg";
import IndustryTwelve from "../../images/cad-service/au-steel-power.svg";
import IndustryThirteen from "../../images/cad-service/au-steel-medical.svg";
import IndustryFourteen from "../../images/cad-service/au-steel-bridge.svg";
import CadHeroSecImgSlider from "../../components/CadHeroSecImgSlider";
import CadProcessWorks from "../../components/CadProcessWorks";
import CadFooterOne from "../../images/cad-service/cad-footer-1.svg";
import CadFooterTwo from "../../images/cad-service/cad-footer-2.svg";
import CadFooterThree from "../../images/cad-service/cad-footer-3.png";
import CadFooterSedinLogo from "../../images/cad-service/sedin-cad-logo.svg";
import { Helmet } from "react-helmet";
// import CadFooterThree from "../../images/cad-service/cad-footer-3.png";
// import staffAug from "../../images/cad-service/staff-aug.svg";

const CadServicesUS = () => {
    const [showModal, setShowModal] = useState(false);
    const handleModel = () => {
        setShowModal(true);
    };
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Seo />
            <section className="cad-service-hero">
                <div className="container">
                    <div className="site-logo">
                        <Link to="/" className="logo-inner">
                            <img
                                src={SedinCadLogo}
                                alt="sedin-logo"
                                loading="lazy"
                                class="gap-05x"
                            />
                        </Link>
                        <div className="flex flex-ai-c">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_1_2509)">
                                    <path
                                        d="M18.6797 4.6875C20.2699 5.11427 21.7198 5.95178 22.884 7.11599C24.0482 8.2802 24.8857 9.73014 25.3125 11.3203"
                                        stroke="#AF8169"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M17.707 8.30859C18.6634 8.56259 19.5357 9.0649 20.2354 9.76462C20.9351 10.4643 21.4374 11.3366 21.6914 12.293"
                                        stroke="#AF8169"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M10.8398 14.625C11.8046 16.6172 13.4155 18.224 15.4102 19.1836C15.5573 19.2533 15.72 19.2834 15.8824 19.2711C16.0447 19.2588 16.201 19.2044 16.3359 19.1133L19.2656 17.1563C19.3951 17.0685 19.5446 17.0149 19.7004 17.0006C19.8561 16.9862 20.013 17.0116 20.1563 17.0742L25.6406 19.4297C25.8281 19.5077 25.9847 19.6452 26.0863 19.8209C26.1879 19.9967 26.229 20.201 26.2031 20.4024C26.0293 21.7591 25.3671 23.006 24.3404 23.9097C23.3137 24.8135 21.9928 25.3122 20.625 25.3125C16.3981 25.3125 12.3443 23.6334 9.35549 20.6445C6.36663 17.6557 4.6875 13.6019 4.6875 9.37501C4.68781 8.00719 5.1865 6.68635 6.09026 5.65964C6.99403 4.63293 8.24093 3.97071 9.59766 3.79688C9.79903 3.77102 10.0033 3.81206 10.1791 3.9137C10.3548 4.01534 10.4923 4.17194 10.5703 4.35938L12.9258 9.85548C12.987 9.99651 13.0127 10.1504 13.0004 10.3037C12.9881 10.457 12.9384 10.6049 12.8555 10.7344L10.8984 13.7109C10.8113 13.8456 10.7602 14.0003 10.7499 14.1603C10.7397 14.3204 10.7706 14.4804 10.8398 14.625Z"
                                        stroke="#AF8169"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_2509">
                                        <rect width="30" height="30" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <a className="contact" href="tel: +1 (506)-259-2539">
                                Call Us: +1 (506)-259-2539
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cad-service-hero-sec">
                <div className="container">
                    <div className="cad-service-hero-sec-grid">
                        <div className="cad-service-hero-sec-grid__left">
                            <h1 className="text-h1 text-fw-medium gap-05x cad-us-h1">
                                CAD Design and Drafting Services
                            </h1>
                            <p className="text-p2 text-clr-secondary gap-2x">
                                Unlock Precision and Efficiency with Expert CAD Services. Trusted by
                                Manufacturing, Automation industry, OEM's and EPC Consulting Firms
                                in US.
                            </p>
                            <div className="flex flex-ai-c">
                                <button
                                    onClick={handleModel}
                                    // href="#"
                                    className="sdn-cta-btn cad-btn flex flex-ai-c"
                                >
                                    Talk To Our CAD Experts
                                </button>
                                {/* <div className="arrow">
                                    <ArrowIcon direction="right" />
                                </div> */}
                            </div>
                            <div className="flex flex-ai-c cad-eng">
                                <p className="text-p3 text-clr-secondary gap-1x">
                                    125+ CAD ENGINEERS
                                </p>
                                <p className="text-p3 text-clr-secondary gap-1x">
                                    FLEXIBLE ENGAGEMENT
                                </p>
                            </div>
                        </div>
                        <div className="cad-service-hero-sec-grid__right">
                            {/* <div className="cad-service-hero-sec-grid__right-image">
                                <StaticImage
                                    src="../images/cad-service/cad-service-hero-image.png"
                                    alt="cad-service-hero-img"
                                    loading="lazy"
                                />
                            </div> */}
                            <CadHeroSecImgSlider />
                        </div>
                    </div>
                    {/* client logos */}
                    <div className="cad-service-hero-sec-client">
                        <div className="cad-service-hero-sec-client__grid">
                            <StaticImage src="../images/cad-service/buhler-1.png" alt="Buhler" />
                            <StaticImage src="../images/cad-service/tas-1.png" alt="tas-logo" />
                            <StaticImage
                                src="../images/cad-service/Pacific-1.png"
                                alt="pacific-logo"
                            />
                            <StaticImage src="../images/cad-service/pdc-1.png" alt="pdc" />
                            <StaticImage
                                src="../images/cad-service/westmill-1.png"
                                alt="westmill"
                            />
                            <StaticImage src="../images/cad-service/Driven-1.png" alt="driven" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="cad-bulletpoints">
                <div className="container container--first">
                    <div className="cad-subtitle gap-2x">
                        <h3 className="cad-subtitle-text">BENEFITS</h3>
                        <h1 className="cad-subtitle-heading">
                            Partnering with us, Offers You the following benefits.
                        </h1>
                    </div>
                    <div className="cad-bulletpoints-grid">
                        <div className="cad-bulletpoints-grid-item">
                            <p>Stay Ahead of Competition</p>
                        </div>
                        <div className="cad-bulletpoints-grid-item">
                            <p>Quick Turnaround</p>
                        </div>
                        <div className="cad-bulletpoints-grid-item">
                            <p>100% Quality Guaranteed</p>
                        </div>
                    </div>
                    <div className="cad-bulletpoints-grid">
                        <div className="cad-bulletpoints-grid-item">
                            <p>Working with Industry Experts</p>
                        </div>
                        <div className="cad-bulletpoints-grid-item">
                            <p>Secured IP</p>
                        </div>
                        <div className="cad-bulletpoints-grid-item">
                            <p>Turnkey Solution</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* engagement models */}
            <section className="cad-campaign-engagement">
                <div className="container container--mid-v2">
                    <div className="cad-subtitle gap-4x">
                        <h3 className="cad-subtitle-text">ENGAGEMENT MODEL</h3>
                        <h1 className="cad-subtitle-heading">To Meet your Budget and Needs</h1>
                    </div>
                    <div className="cad-campaign-engagement__grid">
                        <div className="cad-campaign-engagement__grid-item">
                            <StaticImage
                                src="../images/cad-service/staff-aug.svg"
                                alt="staff-aug"
                                loading="lazy"
                            />
                            <h3 className="text-h3 text-clr-primary text-fw-regular gap-02x ">
                                Time & Material
                            </h3>
                            <p className="text-p4 text-clr-secondary text-fw-medium flex flex-jc-c flex-ai-c">
                                When the project is of shorter duration and you are looking for a
                                quick turnaround this pricing model makes it easy. We bill you based
                                on the actual man hours of our designers.
                            </p>
                        </div>
                        <div className="cad-campaign-engagement__grid-item">
                            <StaticImage
                                src="../images/cad-service/team.svg"
                                alt="staff-aug"
                                loading="lazy"
                            />
                            <h3 className="text-h3 text-clr-primary text-fw-regular gap-02x ">
                                Full Time Equivalent
                            </h3>
                            <p className="text-p4 text-clr-secondary text-fw-medium flex flex-jc-c flex-ai-c">
                                When your requirement is ongoing and you need flexibility with
                                respect to the effort and team size, this model works best. We will
                                bill you every month on a retainer basis.
                            </p>
                        </div>
                        <div className="cad-campaign-engagement__grid-item">
                            <StaticImage
                                src="../images/cad-service/design.svg"
                                alt="staff-aug"
                                loading="lazy"
                            />
                            <h3 className="text-h3 text-clr-primary text-fw-regular gap-02x ">
                                Fixed Package
                            </h3>
                            <p className="text-p4 text-clr-secondary text-fw-medium flex flex-jc-c flex-ai-c">
                                When you know exactly what you want from us and have a fixed
                                timeline we suggest this pricing model. It's a one time cost for a
                                clearly defined scope
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* services sec */}
            <section className="cad-service-sec">
                <div className="container container--mid-v2">
                    <div className="cad-subtitle gap-3x">
                        <h3 className="cad-subtitle-text gap-05x">SERVICES</h3>
                        <h1 className="cad-subtitle-heading">
                            From Concept design to detailing, we bring your ideas to life with
                            unmatched excellence
                        </h1>
                    </div>
                    <div className="gap-4x">
                        <CadServiceGrid initialCategory="Product Design" />
                    </div>
                    <div className="flex flex-ai-c flex-jc-c">
                        <button
                            // onClick={() => scrollTo("#campaign-form")}
                            onClick={handleModel}
                            className="sdn-cta-btn cad-service-cbtn"
                        >
                            REQUEST FREE CONSULTATION
                        </button>
                    </div>
                </div>
            </section>
            {/* work sample sec  */}
            <section className="work-sample-sec">
                <div className="container container--mid-v2">
                    <div className="cad-subtitle gap-3x">
                        <h3 className="cad-subtitle-text gap-05x">WORK SAMPLES</h3>
                        <h1 className="cad-subtitle-heading">
                            See how we help our clients by bringing their ideas into perfect designs
                        </h1>
                    </div>
                    <div className="gap-4x">
                        <CadImageGrid />
                    </div>
                    <div className="flex flex-ai-c flex-jc-c">
                        <button
                            // onClick={() => scrollTo("#campaign-form")}
                            onClick={handleModel}
                            className="sdn-cta-btn cad-service-cbtn"
                        >
                            REQUEST FREE CONSULTATION
                        </button>
                    </div>
                </div>
            </section>
            {/* software expert sec */}
            <section className="software-sec">
                <div className="container container--mid-v2">
                    <div className="cad-subtitle gap-3x">
                        <h3 className="cad-subtitle-text gap-05x">TOOL'S WE USE</h3>
                        {/* <h1 className="cad-subtitle-heading">
                            Empowering business through our software expertise
                        </h1> */}
                    </div>
                    <div className="software-sec-main">
                        <div className="software-sec-main-client-logo__grid-one">
                            <div className="software-sec-main-client-logo">
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/01.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/02.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/03.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/04.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/05.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/06.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/07.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/08.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/01.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/02.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/03.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/04.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/05.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/06.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/07.png"
                                        alt="client-logo"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="software-sec-main-client-logo__grid-two">
                            <div className="software-sec-main-client-logo">
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/09.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/10.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/11.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/12.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/13.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/09.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/10.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/11.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/12.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/13.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/14.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/15.png"
                                        alt="client-logo"
                                    />
                                </div>
                                <div className="software-sec-main-client-logo-item">
                                    <StaticImage
                                        src="../images/cad-service/16.png"
                                        alt="client-logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* how the process works sec  */}
            <section className="cad-how-process-works">
                <div className="container container--mid-v2">
                    <div className="cad-subtitle gap-3x">
                        <h3 className="cad-subtitle-text gap-05x">HOW WE PROCESS</h3>
                        <h1 className="cad-subtitle-heading">
                            Our clients often say it best: Our simple 5 steps process to get
                            started.
                        </h1>
                    </div>
                    <CadProcessWorks />
                </div>
            </section>

            {/* why us slider sec */}
            <section>
                <div className="container container--pt">
                    <div className="cad-subtitle gap-3x">
                        <h3 className="cad-subtitle-text gap-05x">WHY US</h3>
                        {/* <h1 className="cad-subtitle-heading">Let's Start the Conversation!</h1> */}
                    </div>
                </div>
            </section>
            <section className="cad-why-us-sec">
                <div className="container">
                    <div>
                        <CadGifWithContentSlider />
                    </div>
                </div>
            </section>
            {/* industries sec  */}
            <section className="industries-sec">
                <div className="container container--mid-v2">
                    <div className="cad-subtitle gap-3x">
                        <h3 className="cad-subtitle-text gap-05x">INDUSTRIES</h3>
                        <h1 className="cad-subtitle-heading">
                            With extensive experience we have been serving global clients across
                            different industry sectors.
                        </h1>
                    </div>
                    <div className="industries-sec__grid">
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryOne}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Agriculture</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryTwo}
                                // src="../images/cad-service/Driven-1.png"
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Food & Bevarage</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryThree}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Heavy Machinery</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryFour}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Industrial Automation</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryFive}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Steel Structure</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustrySix}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Automotive</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustrySeven}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Oil & Natural Gas</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryEight}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Water Treatment Plant</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryNine}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">
                                Packaging & Material handling
                            </p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryTen}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Machinery Manufacturing</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryEleven}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">MariTime</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryTwelve}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Power</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryThirteen}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Medical</p>
                        </div>
                        <div className="industries-sec__grid-item gap-1x">
                            <img
                                src={IndustryFourteen}
                                placeholder="blurred"
                                alt="industries-icons"
                                loading="lazy"
                            />
                            <p className="text-p2 text-clr-primary">Bridge</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* voice of delight sec */}
            <section className="voice-of-delight">
                <div className="container container--mid-v2">
                    <div className="cad-subtitle gap-3x">
                        <h3 className="cad-subtitle-text gap-05x">VOICES OF DELIGHT</h3>
                        <h1 className="cad-subtitle-heading">
                            Stories shared by our happy clients
                        </h1>
                    </div>
                    <div>
                        <CadTestimonials />
                    </div>
                </div>
            </section>
            <section className="cad-service-faq">
                <div className="container container--mid-v2">
                    <div className="cad-subtitle gap-3x">
                        <h3 className="cad-subtitle-text gap-05x">FAQ’S</h3>
                        <h1 className="cad-subtitle-heading">
                            You can find all the information you’re looking for
                        </h1>
                    </div>
                    <CadFAQs />
                </div>
            </section>
            {/* contact-form sec  */}
            <section className="cad-service-contact-us">
                <div className="container container--last">
                    <div className="cad-service-contact-us-grid">
                        <div className="cad-service-contact-us-grid-item">
                            <div className="cad-subtitle gap-1x">
                                <h3 className="cad-subtitle-text gap-05x">GET IN TOUCH</h3>
                                <h1 className="cad-subtitle-heading">
                                    Looking to Outsource <br />
                                    CAD projects?
                                </h1>
                                <p className="text-p2 text-clr-secondary gap-4x">
                                    Once you submit this form, expect to hear back from us within 24
                                    hours
                                </p>
                            </div>
                            <h2 className="gap-05x">REACH US AT:</h2>
                            <div className="cad-service-contact-us-grid-item__contact-info">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                >
                                    <path
                                        d="M3.73143 20C3.23786 20 2.82607 19.835 2.49607 19.505C2.16607 19.175 2.00071 18.7629 2 18.2686V6.73143C2 6.23786 2.16536 5.82607 2.49607 5.49607C2.82679 5.16607 3.23821 5.00071 3.73036 5H19.5554C20.0482 5 20.4596 5.16536 20.7896 5.49607C21.1196 5.82679 21.285 6.23857 21.2857 6.73143V18.2696C21.2857 18.7625 21.1204 19.1743 20.7896 19.505C20.4589 19.8357 20.0475 20.0007 19.5554 20H3.73143ZM11.6429 12.6243L3.07143 7.01964V18.2696C3.07143 18.4618 3.13321 18.6196 3.25679 18.7432C3.38036 18.8668 3.53857 18.9286 3.73143 18.9286H19.5554C19.7475 18.9286 19.9054 18.8668 20.0289 18.7432C20.1525 18.6196 20.2143 18.4614 20.2143 18.2686V7.01857L11.6429 12.6243ZM11.6429 11.4286L19.8843 6.07143H3.40143L11.6429 11.4286ZM3.07143 7.01964V6.07143V18.2696C3.07143 18.4618 3.13321 18.6196 3.25679 18.7432C3.38036 18.8668 3.53857 18.9286 3.73143 18.9286H3.07143V7.01964Z"
                                        fill="#AF8169"
                                    />
                                    <path
                                        d="M3.07143 7.01964L11.6429 12.6243L20.2143 7.01857V18.2686C20.2143 18.4614 20.1525 18.6196 20.0289 18.7432C19.9054 18.8668 19.7475 18.9286 19.5554 18.9286H3.73143M3.07143 7.01964V18.2696M3.07143 7.01964V6.07143V18.2696M3.07143 7.01964V18.9286H3.73143M3.07143 18.2696C3.07143 18.4618 3.13321 18.6196 3.25679 18.7432C3.38036 18.8668 3.53857 18.9286 3.73143 18.9286M3.73143 20C3.23786 20 2.82607 19.835 2.49607 19.505C2.16607 19.175 2.00071 18.7629 2 18.2686V6.73143C2 6.23786 2.16536 5.82607 2.49607 5.49607C2.82679 5.16607 3.23821 5.00071 3.73036 5H19.5554C20.0482 5 20.4596 5.16536 20.7896 5.49607C21.1196 5.82679 21.285 6.23857 21.2857 6.73143V18.2696C21.2857 18.7625 21.1204 19.1743 20.7896 19.505C20.4589 19.8357 20.0475 20.0007 19.5554 20H3.73143ZM11.6429 11.4286L19.8843 6.07143H3.40143L11.6429 11.4286Z"
                                        stroke="#AF8169"
                                        stroke-width="0.2"
                                    />
                                </svg>
                                <a
                                    href="mailto:sales@sedinengineering.com"
                                    className="text-p3 text-clr-secondary"
                                >
                                    sales@sedinengineering.com
                                </a>
                            </div>
                            <div>
                                {/* <p className="text-p2 text-fw-medium text-clr-primary">USA:</p> */}
                                <div className="cad-service-contact-us-grid-item__contact-info">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                    >
                                        <g clip-path="url(#clip0_1_2119)">
                                            <path
                                                d="M14.9437 3.75C16.2159 4.09141 17.3758 4.76142 18.3072 5.69279C19.2386 6.62416 19.9086 7.78411 20.25 9.05625"
                                                stroke="#AF8169"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M14.1656 6.64648C14.9308 6.84968 15.6286 7.25153 16.1883 7.8113C16.7481 8.37107 17.1499 9.06887 17.3531 9.83398"
                                                stroke="#AF8169"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M8.67188 11.6998C9.44365 13.2936 10.7324 14.579 12.3281 15.3467C12.4458 15.4024 12.576 15.4265 12.7059 15.4167C12.8358 15.4068 12.9608 15.3633 13.0688 15.2904L15.4125 13.7248C15.5161 13.6546 15.6357 13.6117 15.7603 13.6002C15.8849 13.5888 16.0104 13.609 16.125 13.6592L20.5125 15.5435C20.6625 15.606 20.7877 15.7159 20.869 15.8565C20.9504 15.9971 20.9832 16.1606 20.9625 16.3217C20.8234 17.407 20.2937 18.4046 19.4723 19.1276C18.6509 19.8506 17.5943 20.2495 16.5 20.2498C13.1185 20.2498 9.87548 18.9065 7.48439 16.5154C5.0933 14.1243 3.75 10.8813 3.75 7.49979C3.75025 6.40553 4.1492 5.34886 4.87221 4.5275C5.59522 3.70613 6.59275 3.17635 7.67813 3.03729C7.83922 3.01659 8.00266 3.04943 8.14326 3.13074C8.28386 3.21206 8.39384 3.33733 8.45625 3.48729L10.3406 7.88416C10.3896 7.99699 10.4101 8.12014 10.4003 8.24275C10.3905 8.36537 10.3507 8.48369 10.2844 8.58729L8.71875 10.9685C8.64905 11.0762 8.60814 11.2 8.59993 11.328C8.59172 11.4561 8.6165 11.5841 8.67188 11.6998Z"
                                                stroke="#AF8169"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_2119">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <a
                                        className="text-p3 text-clr-secondary"
                                        href="tel: +1 (506)-259-2539"
                                    >
                                        Call Us: +1 (506)-259-2539
                                    </a>
                                </div>
                                <div className="cad-service-contact-us-grid-item__contact-info">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 30 30"
                                        fill="none"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10.9997 21.1889L11.7209 20.3763C12.5391 19.4391 13.2751 18.55 13.93 17.7043L14.4706 16.9911C16.7277 13.95 17.8569 11.5363 17.8569 9.75228C17.8569 5.94428 14.7871 2.85742 10.9997 2.85742C7.21229 2.85742 4.14258 5.94428 4.14258 9.75228C4.14258 11.5363 5.27172 13.95 7.52886 16.9911L8.06944 17.7043C9.00365 18.9013 9.98106 20.0628 10.9997 21.1889Z"
                                            stroke="#AF8169"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M10.9997 12.5717C12.5777 12.5717 13.8569 11.2925 13.8569 9.71456C13.8569 8.13661 12.5777 6.85742 10.9997 6.85742C9.42176 6.85742 8.14258 8.13661 8.14258 9.71456C8.14258 11.2925 9.42176 12.5717 10.9997 12.5717Z"
                                            stroke="#AF8169"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    <p className="text-p3 text-clr-secondary">
                                        440 N. Wolfe Rd, Sunnyvale, CA 94085, USA
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="cad-service-contact-us-grid-item__form">
                            <CadServiceForm usCadServices />
                        </div>
                    </div>
                </div>
            </section>
            {/* popup form  */}
            <CSSTransition in={showModal} timeout={300} classNames="fade" unmountOnExit>
                <EngineeringLeadForm
                    showModal={showModal}
                    setShowModal={setShowModal}
                    usCadServices
                />
            </CSSTransition>
            {/* footer sec  */}
            <section className="cad-service-footer">
                <div className="container">
                    <div className="cad-service-footer-grid gap-4x">
                        <div className="cad-service-footer-grid__item">
                            <img
                                src={CadFooterSedinLogo}
                                alt="footer-sedin-logo"
                                loading="lazy"
                                class="gap-05x"
                            />
                            <p className="text-p4">
                                Sedin Engineering is a division of Sedin Technologies. We offer
                                multidisciplinary and holistic engineering design services across
                                product development, plant and piping design, industrial automation,
                                electrical wiring and harness design, structural and civil design.
                                Our aim is to enable engineering brilliance.
                            </p>
                            <div className="country-name">
                                <p className="text-p4">INDIA</p>
                                <p className="text-p4">USA</p>
                                <p className="text-p4">CANADA</p>
                                <p className="text-p4">AUSTRALIA</p>
                            </div>
                        </div>
                        <div className="cad-service-footer-grid__item left">
                            <div className="cad-service-footer-grid__item-footer-img">
                                <img
                                    // src="../images/cad-service/cad-footer-01.svg"
                                    src={CadFooterOne}
                                    alt="footer-sedin-logo"
                                    loading="lazy"
                                    class="footer-image gap-05x"
                                />
                                <img
                                    src={CadFooterTwo}
                                    alt="footer-sedin-logo"
                                    loading="lazy"
                                    class="gap-05x"
                                />
                                <img
                                    src={CadFooterThree}
                                    alt="footer-sedin-logo"
                                    loading="lazy"
                                    class="gap-05x"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="footer__bottom">
                        <div className="footer__bottom-text">
                            © {new Date().getFullYear()} &nbsp; Sedin Technologies Inc
                        </div>
                        <div className="footer__bottom-links">
                            <Link to="/terms-and-conditions/">Terms & Conditions</Link>
                            <Link to="/privacy-policy/">Privacy Policy</Link>
                            <Link to="/csr-policy/">CSR Policy</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CadServicesUS;
